export const environment = {
    production: true,
    configit: {
        baseUrl: 'https://api-integration.viessmann-climatesolutions.com/backend/configuration/v1/configit',
        imageUrl: 'https://api-integration.viessmann-climatesolutions.com/backend/configuration/v1/configit/images',
        languages: ['de'],
        salesAreaName: 'Germany',
    },
    translations:
        'https://api-integration.viessmann-climatesolutions.com/translations/v2/phraseapp-proxy/projects/c7e936c86cbb53e8813129c35bfbe7f8/locales/',
    statusPage: 'https://api-integration.viessmann-climatesolutions.com/status/v1/apps/9x8cpkfjj4zc/summary',
    defaultLanguage: 'de-DE',

    quest: {
        context: 'quest',
        project: 'ProductFinder',
    },
    app: {
        settingsPartId: 'Settings',
        progressId: 'Settings.Progress',
        recommendationPartId: 'Recommendation',
    },
};
